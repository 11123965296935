/**
 * @namespace components
 */

import React from 'react';
import PropTypes from 'prop-types';

import { isExternal, currentLocale } from '../../../helpers';

/**
 * @class ProLink
 *
 * @description Custom router/link component to manage rendering of internal pro.sony links and external links.
 *
 * @memberof components
 *
 *
 * @param {string} [to] - Valid absolute or relative link to an HTTP resource
 * @param {string} [target] - HTML target attribute passed through to the rendered link
 * @param {string} [className] - JSX className string passed through to the rendered link
 * @param {object} [style] - JSX style object for customising the link
 * @param {string} [id] - HTML ID attribute passed through to the rendered link
 * @param {string} children - JSX children node/string
 *
 * @example
 * return (
 *   <ProLink to="https://google.com">Google</ProLink>
 * )
 */

const ProLink = props => {
    const { to = '', target, className, style, id, children, onClick, ariaLabel } = props;

    const isExternalLink = isExternal(to);
    const toHasForwardSlash = to[0] === '/' ? true : false;
    const splitTo = to.split('/');

    const locale = currentLocale.get();
    const testIndex = toHasForwardSlash ? 1 : 0;
    const localeTest = /^[a-zA-Z]{2}[-_][a-zA-Z]{2}$/.test(splitTo[testIndex]);
    const localeString = toHasForwardSlash ? `/${locale}` : `/${locale}/`;

    let localeWithTo = !isExternalLink && !localeTest && locale ? `${localeString}${to}` : to;

    localeWithTo = localeWithTo.replace('/pro/', '/');

    const attrs = {
        target,
        className,
        style,
        id,
        onClick,
        'data-testid': id,
        ariaLabel,
    };

    /**
     * If to is null or an empty string just render the children for ecommerce
     */

    return to && to !== '' ? (
        <a data-pro-link="true" href={isExternalLink ? to : localeWithTo} {...attrs}>
            {children}
        </a>
    ) : (
        children
    );
};

ProLink.propTypes = {
    to: PropTypes.string,
    target: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ProLink;
